.enterLink {
    width: 100%;
    height: 100%;
    display: flex;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
}

@media (width <=400px) {
    .enterLink {
        min-width: var(--grid-template-columns-width-mobile);
        font-size: var(--small-1);
    }
}
