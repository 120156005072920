.wrapper {
    width: 100%;
}

.select {
    margin-bottom: var(--gap-xs-2);
}

.uploadGroup {
    margin-top: var(--gap-xl);
}
