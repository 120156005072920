.submenuButton.submenuButton {
    width: 100%;
    color: var(--black);
    justify-content: flex-start;
}

.submenuButton.submenuButton.submenuButton.submenuButton:hover {
    color: var(--black);
}

.submenuButtonLabel {
    display: flex;
    gap: 5px;
    color: inherit;
}
