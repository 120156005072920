.collapseHeader {
    background: var(--color-black-02);
}

.collapseContainer {
    margin-bottom: var(--gap-m);
}

.collapseContainer :global(.ant-collapse-content > .ant-collapse-content-box) {
    padding-left: 0;
    padding-right: 0;
}
