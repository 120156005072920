.alert {
    animation: show 0.5s ease forwards;
}

.alertContainer {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes show {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
