.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.main {
    flex: 1;
}
.footer {
    margin-top: var(--gap-l);
    margin-bottom: var(--gap-l);
}
