.modalTitle {
    display: flex;
    align-items: center;
    padding: var(--gap-xs-2) var(--gap-4xl);
    padding-left: var(--gap-s);
    font-size: var(--medium-m);
}

.modalText:global(.ant-typography) {
    margin-bottom: 0;
    margin-left: var(--gap-xs-2);
}

.contentText {
    display: flex;
    font-size: 16px;
    padding: 0 var(--gap-s);
    margin-bottom: var(--gap-m);
}

.modaFooter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
