.ellipsisAnimated span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 2s infinite;
    animation: ellipsis-dot 2s infinite;
    display: inline-block;
    padding-right: var(--gap-4xs);
}

.ellipsisAnimated span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    padding-left: var(--gap-2xs);
}
.ellipsisAnimated span:nth-child(2) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.ellipsisAnimated span:nth-child(3) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
