.logoImg {
    height: 30px;
}

.logoImgWhite {
    position: sticky;
    height: 86px;
}

@media (width <= 500px) {
    .logoImg {
        height: 25px;
    }

    .logoImgWhite {
        height: 86px;
    }
}

@media (width <= 400px) {
    .logoImgWhite {
        height: 50px;
    }
}
