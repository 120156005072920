.applicationTabForm {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.spinner {
    min-height: 80vh;
}

.spinner.spinner:global(.ant-spin) {
    max-height: none;
}

.applicationTabBlocks {
    display: grid;
    gap: 20px;
}

.hidden {
    display: none;
}

.titleBlock {
    display: flex;
    gap: 10px;
    align-items: center;
}

.backButton {
    border: none;
    outline: none;
    padding: 0 10px;
    box-shadow: none;
}

.topArrowIcon {
    font-size: var(--small);
}

h3.applicationTitle {
    margin-bottom: 4px;
    font-size: 21px;
    font-weight: var(--font-semi-bold);
}

h4.changeDataNotification {
    padding: var(--gap-xs-3);
    background: var(--control-item-bg-active);
    font-weight: var(--font-semi-bold);
    margin: 0;
    font-size: var(--small-2);
    border-radius: 5px;
}

.buttonsRow {
    padding-top: var(--gap-2xl);
}

.arrowIcon {
    color: var(--primary-3);
    margin-right: var(--gap-xs-3);
}

@media (width <= 400px) {
    .applicationTabForm {
        row-gap: 20px;
        margin-bottom: 20px;
    }
}
