.templateForm {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xl);
}

.templateItem {
    margin-bottom: 0;
}

.templateItem :global(#templateId_extra) {
    margin-top: var(--gap-s);
}

.templateSelect {
    width: 100%;
}

.organisationCaveat {
    padding: var(--gap-xs-3);
    font-weight: var(--font-semi-bold);
    background: var(--control-item-bg-active);
    border-radius: 5px;
}

.organizationBlockTitle {
    margin: 0;
}

.caveatIcon {
    color: var(--color-black-45);
    font-size: var(--small-2);
    margin-left: var(--gap-3xs);
    transition: color 0.2s;
    cursor: help;
}

.caveatIcon:hover {
    color: var(--primary-2);
}

.organizationBlock {
    display: flex;
    flex-direction: column;
    gap: var(--gap-s);
    max-width: 50%;
}

.pickTemplateButton {
    margin-top: var(--gap-s);
}

@media (max-width: 600px) {
    .organizationBlock {
        max-width: none;
    }
}
