.wrapper {
    width: 100%;
}

.titleContainer {
    flex-direction: column;
    align-items: flex-start;
}

.title {
    margin-top: var(--gap-xs-2);
    margin-bottom: var(--gap-s);
}

.tooltipTitle {
    text-align: center;
    display: inline-block;
    margin: auto;
    padding: 10px;
}

.tooltipTitle > b {
    font-weight: var(--font-bold);
}

.alert {
    margin-bottom: var(--gap-l);
    max-width: var(--empty-block-width);
}
