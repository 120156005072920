.applicationTabSubtitle {
    font-size: var(--medium);
    font-weight: var(--font-medium);
}

.applicationTabBlock {
    padding-top: var(--gap-xl);
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(
        auto-fill,
        minmax(var(--grid-template-columns-width-desktop), 1fr)
    );
    gap: var(--gap-36) var(--gap-30);
}

@media (width <= 400px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: var(--grid-template-columns-width-mobile);
    }

    .applicationTabSubtitle {
        text-align: center;
    }

    .applicationTabBlock {
        grid-template-columns: repeat(
            auto-fill,
            minmax(var(--grid-template-columns-width-mobile), 1fr)
        );
    }
}
