.wrapper {
    width: 100%;
}

.title {
    margin-top: var(--gap-xs-2);
    margin-bottom: var(--gap-s);
}

.spinner {
    min-height: 80vh;
}

.spinner :global(.ant-spin.ant-spin-spinning) {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.alert {
    margin-top: var(--gap-l);
    margin-bottom: var(--gap-l);
    width: fit-content;
}
