.mchdTabFrame {
    border: none;
    width: 100%;
    min-height: 550px;
    visibility: hidden;
}

.mchdTabFrameVisible {
    visibility: visible;
}
