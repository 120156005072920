.collapse {
    margin-top: var(--gap-l);
}

.textErrorColor {
    color: var(--color-error);
}

.textSuccessColor {
    color: var(--color-success);
}

.listItem:before {
    content: '•';
    margin-right: var(--gap-xs-3);
}
