.title {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: var(--font-regular);
    line-height: 1.3;
}

.errorText {
    color: var(--color-error);
}
