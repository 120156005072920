.certActions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modal :global(.ant-modal-header) {
    margin-bottom: 0;
}

.modalWidth {
    max-width: 520px;
}

.printModal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.printModal :global(.ant-modal-content) {
    padding: 40px 24px;
}

.iframe {
    flex: 1;
    width: 100%;
    min-height: 55vh;
}

.generateKeys {
    max-width: 640px;
}

.certificatesTable :global(.ant-spin-nested-loading > div > .ant-spin) {
    max-height: none;
}

.certificatesTable :global(.ant-table-body),
.emptyTable :global(.ant-table-body table) {
    min-height: calc(100vh - 320px);
}
