.selectCertificateButton {
    height: 100%;
    width: var(--grid-template-columns-width-big);
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    padding: var(--gap-m) var(--gap-xl);
    border: 1px solid var(--borderButtonOpacity);
    outline: none;
    transition: background-color 0.3s ease;
}

.selectCertificateText,
.textLeft {
    font-size: var(--small-3);
    color: var(--black);
    opacity: 80%;
    text-align: left;
}

.selectCertificateText {
    white-space: normal;
}

.selectCertificateButton:hover {
    border: var(--outline-start-page);
    background-color: var(--primary-1) !important;
}

.selectCertificateButton:active {
    border-color: var(--primary-8);
    background-color: var(--control-item-bg-active) !important;
}

.titleCertificate {
    width: 100%;
    max-width: 350px;
    white-space: pre-line;
    text-align: start;
}

@media (width <= 400px) {
    .selectCertificateButton {
        width: 280px;
        padding: 10px 16px;
    }
}
