.wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: radial-gradient(
            24.4% 70.25% at -2.47% 0%,
            rgba(81, 81, 139, 0.7) 0%,
            rgba(81, 81, 139, 0) 100%
        ),
        radial-gradient(
            47.53% 70.38% at 94.66% 100%,
            rgba(152, 71, 109, 0.8) 0%,
            rgba(141, 62, 87, 0) 100%
        ),
        linear-gradient(86.51deg, #484666 27.1%, #7373da 100%), rgba(93, 93, 166, 1);
}

.logoBlock {
    padding: 20px;
    align-self: start;
}

.card {
    margin: 20vh auto;
    width: 100%;
    max-width: var(--card-not-found-width);
    background: var(--white);
    border-radius: var(--border-radius-s);
    padding: 48px 56px;
    text-align: center;
    font-weight: var(--font-regular);
}

.note {
    color: var(--colorText);
    font-size: var(--medium-m);
    line-height: var(--line-height-medium);
    margin-bottom: 24px;
    font-style: italic;
}

.title {
    font-size: var(--large-m);
    font-weight: var(--font-semi-bold);
    line-height: var(--line-height-medium);
    margin-bottom: 24px;
}

.text {
    width: 90%;
    margin: auto;
    font-size: var(--medium-m);
    font-weight: var(--font-regular);
    line-height: var(--line-height-medium);
    color: var(--colorText);
}

.link {
    display: inline-block;
    color: var(--primary);
    transition: all ease-in 0.3s;
    border-bottom: 1px solid transparent;
}

.link:hover,
.link:focus,
.link:target {
    border-bottom: 1px solid var(--primary);
    cursor: pointer;
}
