.inputItem {
    margin: 0;
}

.input {
    width: 100%;
}

.input:disabled {
    cursor: not-allowed;
    color: var(--colorInputI);
}
