.enterPageButton,
.updatePageButton {
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
    box-shadow: none;
    font-size: var(--small);
    outline: var(--outline-white);
    transition: var(--transition-duration-default);
    border: none;
}

.updatePageButtonWhithLink {
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
    box-shadow: none;
    font-size: var(--small);
    outline: var(--outline-white);
    transition: var(--transition-duration-default);
    padding: 0;
    border: 1px solid var(--borderButtonOpacity);
    background-color: var(--white);
}

.updatePageButton {
    background-color: var(--white);
    border: 1px solid var(--borderButtonOpacity);
}

.updatePageButton:hover,
.updatePageButtonWhithLink:hover {
    background-color: var(--backgroundDisabled);
}

.enterPageButton:disabled,
.updatePageButton:disabled {
    color: var(--colorTextDisabled);
    border: 1px solid var(--borderButtonOpacity);
}

.enterPageButtonYes {
    background: var(--secondary);
    border-color: var(--secondary);
    color: var(--white);
    width: fit-content;
    height: var(--enter-page-button-height);
    margin: 0 auto;
    border-radius: var(--border-radius-s);
}

.applicationTabButton {
    font-size: var(--small);
    border-radius: var(--border-radius-s);
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: var(--white);
    width: var(--button-width-desktop);
    height: var(--button-height);
    margin: 0 auto;
}

.applicationTabButton:hover:enabled,
.enterPageButtonYes:hover:enabled,
.signButton:hover:enabled,
.applicationTabButton:focus:enabled,
.enterPageButtonYes:focus:enabled,
.signButton:focus:enabled {
    background: var(--secondary-2) !important;
    border-color: var(--secondary-2) !important;
    color: var(--white) !important;
}
.applicationTabButton:disabled,
.enterPageButtonYes:disabled,
.signButton:disabled {
    background: var(--colorBgContainerDisabled) !important;
    border-color: var(--colorBorderDisabled) !important;
    color: var(--colorTextDisabled) !important;
}

.enterPageButtonYes:hover {
    background: var(--secondary);
    border-color: var(--secondary);
    color: var(--white);
}

.signButton {
    border-radius: var(--border-radius-s);
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: var(--white);
    margin: 0 auto;
}

.secondary {
    background: var(--secondary);
    color: var(--white);
    border: none;
}

.secondary.secondary:not(:disabled):hover,
.secondary.secondary:not(:disabled):active {
    background: var(--secondary-2);
    color: var(--white);
}

@media (width <=400px) {
    .enterPageButton,
    .updatePageButton,
    .updatePageButtonWhithLink {
        width: var(--button-width-mobile);
        font-size: var(--small-1);
    }

    .applicationTabButton {
        width: 100%;
        max-width: var(--button-width-mobile);
    }
}
