.inputItem {
    margin: 0;
}

.inputItem:global(.ant-form-item-has-error) [class^='custom-mask-input'] {
    border-color: var(--color-error);
}

.inputMask {
    width: 100%;
    color: var(--black);
    border: var(--gap-5xs) solid var(--borderInputIMask);
    padding: var(--gap-3xs) var(--gap-xs-1);
    border-radius: var(--gap-2xs);
}

.inputMask::placeholder {
    color: var(--placeholderInputIMask);
}

.inputMask:disabled {
    cursor: not-allowed;
    color: var(--colorInputI);
}

.inputMask:hover {
    border-color: var(--primary-3);
}
