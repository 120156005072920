.spinner {
    min-height: 80vh;
}

.spinner :global(.ant-spin.ant-spin-spinning) {
    top: 50%;
    transform: translateY(-50%);
}

.wrapper {
    display: flex;
    flex-direction: row;
    column-gap: var(--gap-m-1);
    background-color: var(--white);
}

aside.sidebar {
    height: 100%;
    background: var(--white);
}

.menu {
    height: 100%;
    background: var(--white);
    overflow-y: auto;
    overflow-x: hidden;
}

.layout {
    background-color: var(--white);
}
