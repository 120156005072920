.enterScreenContainer {
    width: 100%;
}

.text {
    margin: var(--gap-l) auto;
    font-size: var(--medium-x);
    font-weight: var(--font-medium);
}

.textWithLink {
    max-width: var(--text-link-width);
    font-size: var(--small);
    text-align: center;
}

.updateText {
    margin: 0 auto;
    max-width: var(--text-upload-width);
    font-size: var(--small-3);
    text-align: center;
}
.loader {
    height: 100%;
    min-height: 200px;
    width: clamp(min(40vw, 280px), 380px, 500px);
}

.loader :global(.ant-spin) {
    color: var(--primary-8);
}
.loader :global(.ant-spin .ant-spin-dot.ant-spin-dot-spin) {
    top: 25%;
}
.loader.loader :global(.ant-spin .ant-spin-text) {
    font-size: 18px;
    width: 100%;
    padding-top: 0;
}

@media (width <= 500px) {
    .loader {
        width: 320px;
    }
}
