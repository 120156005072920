.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs-3);
    padding: var(--gap-xs-3);
}

.range {
    padding-top: var(--gap-4xl);
}
