.certificatesTitle {
    display: grid;
}

.coloredText {
    color: var(--primary);
}

.textTitle,
.textNote {
    color: var(--colorText);
}
