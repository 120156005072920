.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xl);
    height: 100%;
}

.loadCertificates,
.authProcessing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.loadCertificates {
    height: var(--enter-spinner-height);
}

.authProcessing {
    min-height: var(--auth-spinner-height);
}
