.answerText {
    justify-content: flex-start;
    align-items: center;
    gap: var(--gap-s);
    margin-top: var(--gap-xl);
}

.errorIcon {
    color: var(--color-error);
    font-size: calc(var(--medium-m) + 2px);
}

.successIcon {
    color: var(--color-success);
    font-size: var(--medium-l);
}

.description {
    font-size: var(--small);
    white-space: pre-wrap;
    color: #3e3e59;
}
