.selectItem {
    margin-bottom: 0;
    width: 100%;
    min-width: var(--grid-template-columns-width-mobile);
}

.selectItem:disabled {
    cursor: not-allowed;
    color: var(--colorInputI);
}

.select {
    width: 100%;
}

.icon {
    font-size: var(--small-2);
    color: var(--color-black-45);
    transition: all 0.2s ease;
}

.icon:hover {
    color: var(--primary-2);
}
.customLabel {
    align-items: center;
}
