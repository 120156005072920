.space {
    margin: var(--gap-xs-2) auto;
    width: 100%;
    gap: var(--gap-3xs);
}

.space :global(.ant-space-item) {
    width: 100%;
}

.space h3 {
    font-size: var(--medium-m);
    line-height: 1.3;
}

.title {
    margin-right: var(--gap-4xl);
}

.description {
    font-size: var(--small);
    white-space: pre-wrap;
    color: #3e3e59;
}

.additionalPadding {
    padding-left: var(--gap-m);
    padding-right: var(--gap-m);
    padding-top: var(--gap-s);
}

.start {
    text-align: start;
    justify-content: flex-start;
}

.leftPadding {
    padding-left: 0;
}

.divider {
    margin: 0;
}

.errorIcon {
    color: var(--color-error);
    font-size: calc(var(--medium-m) + 2px);
    margin-top: 12px;
}

.successIcon {
    color: var(--color-success);
    font-size: var(--medium-l);
    margin-right: var(--gap-m);
}

h5.summaryExpires {
    margin-bottom: 0;
    margin-top: 12px;
    font-weight: var(--font-regular);
}

.summaryExpiresDate {
    font-size: inherit;
    font-weight: var(--font-medium);
}
