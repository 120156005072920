.certActions {
    display: flex;
    gap: var(--gap-2xs);
    justify-content: space-between;
    align-items: center;
}

.columnActions {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
}

.sideAction {
    a {
        font-size: var(--small);
        color: var(--color-black-45);
    }
}

.draftDisabledInfo {
    color: var(--primary);
}
