.enterContent {
    width: 100%;
    min-height: 100vh;
    display: flex;
    gap: var(--gap-40);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: radial-gradient(
            24.4% 70.25% at -2.47% 0%,
            rgba(81, 81, 139, 0.7) 0%,
            rgba(81, 81, 139, 0) 100%
        ),
        radial-gradient(
            47.53% 70.38% at 94.66% 100%,
            rgba(152, 71, 109, 0.8) 0%,
            rgba(141, 62, 87, 0) 100%
        ),
        linear-gradient(86.51deg, #484666 27.1%, #7373da 100%), rgba(93, 93, 166, 1);
}

.enterContentBlock {
    width: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--header-height-desktop) auto 0;

    .enterContentTitle {
        margin: 0;
        font-size: var(--medium-x);
        font-weight: var(--font-semi-bold);
    }

    .enterBlock {
        max-width: var(--enter-block-width);

        background-color: var(--white);
        border-radius: var(--border-radius-lg);
        padding: var(--gap-xl) var(--gap-4xl-2);
    }
}

.logoBlock {
    max-width: var(--width-desktop);
    width: 100%;
    height: var(--header-height-desktop);
    position: fixed;
    top: 0;
    backdrop-filter: blur(var(--blur-10));
    z-index: 10;
    display: flex;
    align-items: center;
}

@media (width <=500px) {
    .logoBlock {
        height: var(--header-logo-desktop);
    }

    .enterBlock {
        width: 100%;
    }

    .enterContentBlock {
        .enterBlock {
            padding: var(--gap-m) var(--gap-l);
        }
    }
}
