.titleContainer {
    flex-direction: column;
    align-items: flex-start;
}

.title {
    margin-top: var(--gap-xs-2);
    margin-bottom: var(--gap-s);
}

.alert {
    margin-bottom: var(--gap-l);
    max-width: var(--empty-block-width);
}
