.header {
    display: flex;
    padding: var(--gap-m) var(--gap-m-1);
    padding-bottom: var(--gap-xs-2);
    align-items: center;
    justify-content: space-between;
    max-width: var(--width-desktop);
    position: sticky;
    inset: 0;
    backdrop-filter: blur(var(--blur-10));
    z-index: 10;
}

.menuButton {
    display: flex;
    align-items: center;
    gap: var(--gap-2xs);
    padding: var(--gap-2xs) var(--gap-xs-2);
    height: auto;
    cursor: pointer;
    font-size: var(--small);
    transition: color 0.2s;
}

.menuButton:hover {
    color: var(--primary);
    background-color: transparent;
}

.menuDropdown.menuDropdown.menuDropdown li {
    padding: 0;
}

.menuDropdown.menuDropdown.menuDropdown li:hover {
    background-color: var(--primary-1);
}

.menuButtonCaret {
    font-size: var(--small-3);
    transition: all 0.3s;
}

.menuButton:has([role='menu']) .menuButtonCaret {
    transform: rotate(180deg);
}
