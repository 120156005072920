.itemControl :global(.ant-form-item-control-input) {
    height: auto;
    min-height: 20px;
}
.upload {
    display: flex;
    flex-direction: column;
}
.upload :global(.ant-upload-select) {
    transition: all var(--transition-duration-default);
    margin-bottom: var(--gap-xs-3);
}
.switchUploadItems :global(.ant-upload-select),
.formItem {
    transition: all var(--transition-duration-default);
    margin-bottom: 0;
}
.switchUploadItems div:nth-child(2) {
    order: -1;
}
.switchUploadFileList :global(.ant-upload-list) {
    transition: all var(--transition-duration-default);
    margin-bottom: var(--gap-xs-3);
}

.customLabel {
    padding-bottom: var(--gap-xs-3);
}
.fileSize {
    color: var(--borderButton);
    font-size: var(--small-2);
}
.errorFile {
    color: var(--color-error);
}
