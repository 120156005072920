.cookiesLink.cookiesLink {
    color: var(--secondary);
    font-weight: 500;
}

.cookiesLink.cookiesLink:hover,
.cookiesLink.cookiesLink:focus {
    color: var(--secondary-2);
    text-decoration: underline;
}

.cookiesNotification.cookiesNotification [class*='notice-message'] {
    margin-bottom: 0;
}
