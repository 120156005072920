.icon {
    font-size: var(--small-2);
    color: var(--color-black-45);
    transition: all 0.2s ease;
    cursor: pointer;
}

.icon:hover {
    color: var(--primary-2);
}

.centered :global(.ant-tooltip-content .ant-tooltip-inner) {
    margin: auto;
    text-align: center;
}
